import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast, UseToastOptions } from '@chakra-ui/react';
import {
  DefaultError,
  QueryFunctionContext,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { BaseAdminEntity } from '@worknet/models';
import { useLocation, useNavigate } from 'react-router-dom';

import { runtimeEnv, showErrorToast, useErrorToast } from '../utils';

import { createAxiosClient } from './axiosClient';

type ApiBases = 'worknet-admin' | 'tenant-admin' | 'oauth2' | 'public';
type ApiClient = ReturnType<typeof createAxiosClient>;

function useApiClient(base: ApiBases) {
  const { getAccessTokenSilently } = useAuth0();
  return useMemo(
    () =>
      createAxiosClient(
        `${runtimeEnv.apiBaseUrl}/admin/v1/${base}`,
        base === 'public' ? undefined : getAccessTokenSilently
      ),
    [getAccessTokenSilently, base]
  );
}

export function useApiMutationWithToast<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
>({
  options,
  invalidationKeys,
}: {
  options: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'> & {
    apiMutationFn: (client: ApiClient, variables: TVariables) => Promise<TData>;
    api?: ApiBases;
    toastProps?: UseToastOptions;
  };
  invalidationKeys?: string[];
}) {
  const queryClient = useQueryClient();

  const apiClient = useApiClient(options.api ?? 'tenant-admin');
  const toast = useToast();
  const { apiMutationFn, onError, onSuccess, ...rest } = options;
  return useMutation({
    mutationFn: (variables: TVariables) => apiMutationFn(apiClient, variables),
    onError(error, variables, context) {
      showErrorToast(toast, error, options?.toastProps);
      onError?.(error, variables, context);
    },
    onSuccess(...args) {
      onSuccess?.(...args);
      invalidationKeys ? queryClient.invalidateQueries({ queryKey: invalidationKeys }) : null;
    },
    ...rest,
  });
}

export function useApiQueryWithToast<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryFn'> & {
    apiQueryFn: (client: ApiClient, ctx?: QueryFunctionContext<TQueryKey>) => Promise<TQueryFnData>;
    api?: ApiBases;
  }
) {
  const apiClient = useApiClient(options.api ?? 'tenant-admin');
  const { apiQueryFn, ...rest } = options;
  const query = useQuery({
    gcTime: Array.isArray(rest.queryKey) && rest.queryKey.length > 1 ? 0 : undefined,
    ...rest,
    queryFn: (ctx) => apiQueryFn(apiClient, ctx),
  });

  useErrorToast(query.error);
  return query;
}

/**
 * add navigate split to new/edit to useApiMutationWithToast
 */
export function useEntityApiMutationWithToast<
  TVariables = void,
  TData extends BaseAdminEntity = BaseAdminEntity,
  TError = DefaultError,
  TContext = unknown,
>({
  path,
  id,
  invalidationKeys,
  onSuccess,
}: {
  path: string;
  id?: string;
  invalidationKeys?: string[];
  onSuccess?: (data: TData) => void;
}) {
  const navigate = useNavigate();
  return useApiMutationWithToast<TData, TError, TVariables, TContext>({
    options: {
      api: 'tenant-admin',
      apiMutationFn: (api, values: TVariables) => {
        if (!id) {
          return api.post<TData>(path, values);
        }
        return api.patch<TData>(`${path}/${id}`, values);
      },
      onSuccess: (data) => {
        const isCreate = !id;
        onSuccess ? onSuccess(data) : isCreate && navigate(`${path}/${data?.id}`);
      },
    },
    invalidationKeys,
  });
}

export function useGetEntityApiQueryWithToast<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryFn'> & {
    apiQueryFn: (client: ApiClient, ctx?: QueryFunctionContext<TQueryKey>) => Promise<TQueryFnData>;
  }
) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const response = useApiQueryWithToast<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
  });

  if (response?.error instanceof Error && response.error.message.includes('not found')) {
    const lastSlashIndex = pathname.lastIndexOf('/');
    const entityTableUrl = pathname.substring(0, lastSlashIndex);
    navigate(entityTableUrl);
  }

  return response;
}
