import { datadogLogs } from '@datadog/browser-logs';

import { runtimeEnv } from './runtimeEnv';

export const datadogInit = (serviceName: 'admin-site' | 'copilot-site') => {
  datadogLogs.init({
    clientToken: 'pub55f807e3bc1ef8aa7f284f0ec35c75cc',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    // env: runtimeEnv.stage,
    // version: runtimeEnv.version,
    forwardConsoleLogs: ['error', 'warn'],
    service: serviceName,
    beforeSend(event) {
      event['env'] = runtimeEnv.stage;
      event['version'] = runtimeEnv.version;
      return true;
    },
  });
};
