import { ComponentProps } from 'react';
import { ChatMessageAiAnswerSource } from '@worknet/models';
import { keyframes } from '@emotion/react';

import ServerMessage from '../ds/ServerMessage';

export function createTagGroups(sources?: ChatMessageAiAnswerSource[]) {
  const groups: ComponentProps<typeof ServerMessage>['tagGroups'] = [];
  if (sources) {
    groups.push({
      title: 'Resources',
      tags: sources.map((s) => ({ title: s.title, content: s.url })).slice(0, 4),
      isLink: true,
    });
  }

  return groups;
}

export const getTimeInDay = (date: Date) =>
  new Date(date).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

export const getFormattedDate = (inputDate: Date) => {
  const now = new Date();
  const isToday = now.toDateString() === inputDate.toDateString();
  const isYesterday =
    new Date(now.setDate(now.getDate() - 1)).toDateString() === inputDate.toDateString();

  if (isToday) {
    return 'TODAY';
  }
  if (isYesterday) {
    return 'YESTERDAY';
  }

  return inputDate
    .toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
    .toUpperCase()
    .replace(',', '');
};

export const fadeIn = keyframes`
0% { opacity: 0;}
100% { opacity: 1;}
`;
