import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Center, Text } from '@chakra-ui/react';
import { CustomLoginPage } from '@worknet/app-core';

import worknetWhiteLogo from '~/shared/assets/worknetWhiteLogo.png';
import MainFlowLayout from '~/shared/components/ds/MainFlowLlayout';

export default function AuthenticatedOutlet() {
  const qs = new URLSearchParams(location.search);
  const errorFromParams = qs.get('error');

  const { user, error } = useAuth0();

  sessionStorage.setItem('__last_pathname__', window.location.pathname);
  const altErrorMessage =
    errorFromParams === 'Unauthorized' || error?.message
      ? 'Organization or user is not authorized. Try to login with the correct User and organization. If the problem persists, please contact your administrator.'
      : '';
  const errorMessage =
    error?.message && !error?.message.includes('user does not belong')
      ? error.message
      : altErrorMessage;

  if (!user) {
    return (
      <MainFlowLayout>
        <Center gap="10px" flexDir="column" flex={1}>
          <Center>
            <CustomLoginPage logoUrl={worknetWhiteLogo} isLoginWithPopup />
          </Center>

          {errorMessage && <Text color="red">Error: {errorMessage}</Text>}
        </Center>
      </MainFlowLayout>
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
}
