import { Button, Icon, Stack, Text, Box } from '@chakra-ui/react';
import { ChatSummaryItem } from '@worknet/models';
import { useMemo } from 'react';

import PlusIcon from '~/shared/components/ds2/assets/Plus.svg?react';

import { getFormattedDate } from '../utils';

import ChatCard from './ChatCard';

export type ChatEvent = { name: 'openChat'; chatId: string } | { name: 'newChat' };
interface SummariesProps {
  chats: ChatSummaryItem[];
  onChatEvent: (evt: ChatEvent) => void;
}

export default function ChatsList({ chats, onChatEvent }: SummariesProps) {
  const chatsByDay = useMemo(() => groupByDay(chats), [chats]);

  return (
    <Stack flex={1} overflowY="hidden">
      <Stack gap="20px" overflowY="auto">
        {chatsByDay.map((chats) => {
          const date = getFormattedDate(new Date(chats.date));
          return (
            <Box key={date}>
              <Text
                fontSize="chatSmallText"
                mb="8px"
                ml="59px"
                color="greyNatural"
                fontWeight={600}
              >
                {date}
              </Text>

              {chats.chats.map((chat) => (
                <ChatCard
                  key={chat.id}
                  chat={chat}
                  onClick={() => onChatEvent({ name: 'openChat', chatId: chat.id })}
                />
              ))}
            </Box>
          );
        })}
      </Stack>

      <Button
        variant="primary"
        mt="auto"
        mx="21px"
        borderRadius="4px"
        gap="5px"
        flexShrink={0}
        onClick={() => onChatEvent({ name: 'newChat' })}
      >
        <Icon boxSize="12px" fill="white" as={PlusIcon} />
        Send a message
      </Button>
    </Stack>
  );
}

function groupByDay(chats: ChatSummaryItem[]) {
  const chatsByDayMap = chats.reduce<Record<string, ChatSummaryItem[]>>((acc, chat) => {
    const lastMessageDate = new Date(chat.summary.lastMessageAt);
    const startOfDay = new Date(
      lastMessageDate.getFullYear(),
      lastMessageDate.getMonth(),
      lastMessageDate.getDate()
    );
    const day = startOfDay.toISOString();
    acc[day] ??= [];
    acc[day].unshift(chat);
    return acc;
  }, {});

  return Object.keys(chatsByDayMap)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    .map((day) => ({
      date: day,
      chats: chatsByDayMap[day],
    }));
}
