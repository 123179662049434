import { Flex, Stack, Image, Text, useTheme, Icon, Center } from '@chakra-ui/react';
import { ChatSummaryItem } from '@worknet/models';
import { lighten } from 'polished';

import GuestIcon from '~/shared/components/ds2/assets/Guest.svg?react';

import { getTimeInDay } from '../utils';

interface ChatCardProps {
  onClick: () => void;
  chat: ChatSummaryItem;
}

export default function ChatCard({ onClick, chat }: ChatCardProps) {
  const { colors } = useTheme();
  return (
    <Flex
      h="81px"
      px="21px"
      py="8px"
      cursor="pointer"
      alignItems="start"
      transition="0.2s"
      justifyContent="space-between"
      _hover={{ bg: `${lighten(0.287, colors.main)}` }}
      _active={{ bg: `${lighten(0.225, colors.main)}` }}
      onClick={onClick}
      color="black"
    >
      <Flex gap="10px" w="full" pt="3px">
        {chat.user?.picture ? (
          <Image boxSize="28px" borderRadius="4px" src={chat.user.picture} />
        ) : (
          <Center bg="main" boxSize="28px" borderRadius="4px">
            <Icon as={GuestIcon} fill="white" boxSize="14px" />
          </Center>
        )}

        <Stack gap="3px" w="full">
          <Flex alignItems="center" gap="10px" flex={1}>
            <Text fontWeight="bold" fontSize="chatText">
              {chat.user?.name ?? 'Guest'}
            </Text>
            <Text fontSize="chatTinyText" color="greyNatural">
              {getTimeInDay(new Date(chat.summary.lastMessageAt))}
            </Text>

            {/* <Flex ml="auto" gap="5px" zIndex={0} align="center">
              <UsersImages
                imagesSrc={displayedUsersImages}
                left={(displayedUsersImages.length - 1) * 3}
                imageProps={{ boxSize: '18px' }}
              />
              <Text color="greyNatural" fontSize="chatTinyText">
                {displayedUsersImages.length}
              </Text>
            </Flex> */}
          </Flex>

          <Text fontSize="chatText" noOfLines={2} wordBreak="break-word">
            {chat.summary.content}
          </Text>
        </Stack>
      </Flex>
    </Flex>
  );
}
