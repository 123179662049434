export interface ChatUser {
  email: string;
  name?: string;
  picture?: string;
  source?: { system: string; id?: string };
}

export type ChatContent =
  | string
  | Array<{
      type: 'text';
      content: string;
    }>;

export type ChatSettings = {
  chatBotName?: string;
  chatBotImageUrl?: string;
  chatWelcomeMessage?: string;
  groupingSupported?: boolean;
  sharingApprovalRequired?: boolean;
};

export function resolveContentText(content: ChatContent): string {
  const msg = typeof content === 'string' ? content : content[0].content;
  return msg.trim();
}
