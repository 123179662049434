import { Fragment } from 'react';
import { Center, Flex, Stack, Text, keyframes } from '@chakra-ui/react';
import { Tooltip } from 'react-tooltip';

const truncateText = (text: string, maxLength: number) =>
  text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

const fadeIn = keyframes`
  0% { opacity: 0;}
  100% { opacity: 1;}
`;

type CitationsButtonsProps = {
  buttonsData: {
    text: string;
    onClick: (text: string) => void;
    toolTip: { title: string; subTitle: string; body: string };
  }[];
};

export default function CitationsButtons({ buttonsData }: CitationsButtonsProps) {
  return (
    <>
      <Flex display="inline-flex" ml="5px" gap="4px" as="span">
        {buttonsData.map(({ text, onClick, toolTip }) => {
          const uniqueTooltipId = `${crypto?.randomUUID()?.slice(0, 12)}${text}`;
          return (
            <Fragment key={text}>
              <Tooltip
                id={uniqueTooltipId}
                place="bottom"
                noArrow
                opacity={1}
                wrapper={'span'}
                style={{
                  boxShadow: '0px 0px 15px -3px #0000001A',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                  padding: '0px',
                  maxWidth: '80vw',
                }}
              >
                <Stack
                  color="black"
                  px="15px"
                  py="9px"
                  borderRadius="6px"
                  gap="2px"
                  fontSize="12px"
                  as="span"
                >
                  <Text as="span">{toolTip.title}</Text>
                  <Text fontSize="chatText" fontWeight="bold" as="span">
                    {truncateText(toolTip.subTitle, 70)}
                  </Text>
                  <Text as="span">{truncateText(toolTip.body, 200)}</Text>
                </Stack>
              </Tooltip>

              <Center
                as="span"
                data-tooltip-id={uniqueTooltipId}
                boxSize="15px"
                border="1px"
                borderColor="greyNatural"
                _hover={{ bg: '#B8B8B8' }}
                bg="white"
                borderRadius="4px"
                cursor="pointer"
                fontSize="9px"
                fontWeight="bold"
                animation={`${fadeIn} 1.2s`}
                onClick={() => onClick(text)}
              >
                {text}
              </Center>
            </Fragment>
          );
        })}
      </Flex>
    </>
  );
}
