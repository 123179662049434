import { useEffect } from 'react';

import { HostPlatformContext } from '~/shared/utils/api';
import MainFlowLayout from '~/shared/components/ds/MainFlowLlayout';
import useChatStore from '~/shared/utils/useChatStore';
import useErrorHandler from '~/shared/utils/useErrorHandler';

import BouncingLoader from '../ds/BouncingLoader';

import Switcher from './Switcher';

interface HostedAppProps {
  hostedPlatformContext?: HostPlatformContext;
}

export default function HostedApp({ hostedPlatformContext }: HostedAppProps) {
  const { app, error, init } = useChatStore();

  useErrorHandler(error);

  useEffect(() => {
    if (!hostedPlatformContext) {
      return;
    }
    init(hostedPlatformContext);
  }, [hostedPlatformContext, init]);

  if (!hostedPlatformContext || !app.value) {
    return (
      <MainFlowLayout>
        <BouncingLoader />
      </MainFlowLayout>
    );
  }

  return <Switcher />;
}
