import { runtimeEnv } from '@worknet/app-core';
import { WsClientToServerEvents, WsServerToClientEvents } from '@worknet/models';
import { io, Socket } from 'socket.io-client';

export type TypeSocket = Socket<WsServerToClientEvents, WsClientToServerEvents>;

export function createSocketIo(
  authHeaders: Record<string, string>,
  getToken?: () => Promise<string>
): TypeSocket {
  const wsBaseUrl = `${runtimeEnv.apiBaseUrl}/ws`;
  const url = new URL(`${wsBaseUrl}/socket.io`);
  const socket: TypeSocket = io(url.origin, {
    withCredentials: true,
    path: url.pathname,
    extraHeaders: authHeaders,
    auth: async (cb) => {
      const token = await getToken?.();
      cb({
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      });
    },
  });

  return socket;
}
