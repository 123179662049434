import { useAsync } from 'react-use';
import { ZendeskTicketSourceContext } from '@worknet/models';

import { client } from './core';
import { Ticket, UserContext } from './types';

const contextPromise = client?.context?.() || Promise.resolve({});

const metadataPromise = client?.metadata?.() || Promise.resolve({});
const currentUserPromise = getZendeskValue<UserContext>('currentUser');

async function getZendeskInfo() {
  const [context, metadata, currentUser] = await Promise.all([
    contextPromise,
    metadataPromise,
    currentUserPromise,
  ]);
  console.log(JSON.stringify({ context, metadata, currentUser }));
  return { context, metadata, currentUser };
}

async function getZendeskValue<T>(key: string) {
  const data = await client?.get?.(key);
  return data?.[key] as T;
}

export function useZendeskBusinessContext() {
  return useAsync(async () => {
    const ticket: Ticket = await getZendeskValue('ticket');
    const { currentUser, context } = await getZendeskInfo();
    if (!ticket || !currentUser || !context) {
      return undefined;
    }
    const sourceContext: ZendeskTicketSourceContext = {
      type: 'zendesk-ticket',
      ticketId: ticket.id.toString(),
    };
    const hostname = `${context.account.subdomain}.zendesk.com`; // TODO: check about custom url... etc
    return { sourceContext, hostname, userEmail: currentUser.email };
  });
}

export async function appendTicketComment(text: string) {
  const lines = text.split('\n');
  for (const line of lines) {
    await client?.invoke?.('ticket.comment.appendText', line);
  }
}
