import axios, { Axios, AxiosError, AxiosHeaders } from 'axios';

export function createAxiosClient(baseURL: string, getToken?: () => Promise<string>) {
  const client = axios.create({
    baseURL,
    withCredentials: true,
  });

  client.interceptors.request.use(async (config) => {
    return {
      ...config,
      headers: new AxiosHeaders({
        ...config.headers,
        Authorization: getToken ? `Bearer ${await getToken()}` : '',
      }),
    };
  });

  client.interceptors.response.use(
    async (res) => {
      if (res.status !== 200 && res.data?.message) {
        console.error('error', res.data);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error = new Error(res.data?.errorMessage || res.data?.message) as any;
        error.response = res;
        throw error;
      }
      return res;
    },
    async (error) => {
      if (error instanceof AxiosError) {
        const message =
          error.response?.data?.errorMessage ||
          error.response?.data?.message ||
          error.response?.data?.error ||
          error.message;
        error.message = message;
      }
      throw error;
    }
  );

  return {
    get: <T = unknown>(...args: Parameters<Axios['get']>) =>
      client.get<T>(...args).then((r) => r.data),
    post: <T = unknown>(...args: Parameters<Axios['post']>) =>
      client.post<T>(...args).then((r) => r.data),
    delete: <T = unknown>(...args: Parameters<Axios['delete']>) =>
      client.delete<T>(...args).then((r) => r.data),
    patch: <T = unknown>(...args: Parameters<Axios['patch']>) =>
      client.patch<T>(...args).then((r) => r.data),
    put: <T = unknown>(...args: Parameters<Axios['put']>) =>
      client.put<T>(...args).then((r) => r.data),
    request: <T = unknown>(...args: Parameters<Axios['request']>) =>
      client.request<T>(...args).then((r) => r.data),
  };
}
