import { Box, Flex, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

import { markdownStyles } from '~/shared/utils/theme';

import BouncingLoader from './BouncingLoader';
import Markdown from './Markdown';

type Props = {
  message: string;
};

const Typewriter = ({ text }: { text: string }) => {
  const [typedText, setTypedText] = useState('');
  const typingSpeed = 10; // milliseconds

  useEffect(() => {
    if (text.length > typedText.length) {
      const timer = setTimeout(() => {
        setTypedText(text.slice(0, typedText.length + 1));
      }, typingSpeed);

      return () => clearTimeout(timer);
    }
  }, [text, typedText]);

  return <Markdown content={`${typedText} <span class="blinking-cursor">|</span>`} />;
};

export default function ServerLoadingMessage({ message }: Props) {
  return (
    <Flex fontSize="chatText" flex={1} gap={2} className={markdownStyles}>
      <Flex
        direction="column"
        bg="#F2F2F2"
        borderRadius={8}
        p="10px 16px"
        gap={2}
        flex="1"
        overflowX="hidden"
      >
        <Box flex={1}>
          {message ? (
            <Typewriter text={message} />
          ) : (
            <Flex align="center" gap="5px">
              <Text>Thinking</Text>
              <Box mt="1px">
                <BouncingLoader variant="sm" />
              </Box>
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
