import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';

import { authContext, useWorknetProvider } from '../hooks';

function WorknetProvider({ children }: PropsWithChildren) {
  const value = useWorknetProvider();
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
}

export function WorknetAuthProvider({ children, ...rest }: Auth0ProviderOptions) {
  return (
    <Auth0Provider {...rest}>
      <WorknetProvider>{children}</WorknetProvider>
    </Auth0Provider>
  );
}
