import { useMemo } from 'react';
import { ChatUser } from '@worknet/models';
import { Box, Flex, Icon } from '@chakra-ui/react';

import ChatIcon from '~/shared/components/ds2/assets/Chat.svg?react';
import ClockIcon from '~/shared/components/ds2/assets/Clock.svg?react';
import MainFlowLayout from '~/shared/components/ds/MainFlowLlayout';
import useChatStore from '~/shared/utils/useChatStore';

import Messages from '../Messages';
import ChatsList, { ChatEvent } from '../ChatsList';
import BouncingLoader from '../../ds/BouncingLoader';

import Header from './Header';
import UsersImages from './UsersImages';

export default function Switcher() {
  const { app, chat, chats, openChat, closeChat, user, groupingId } = useChatStore((state) => ({
    ...state,
    ...state.hostPlatformContext!,
  }));

  const allUsers = useMemo(() => {
    const users = chats.map((s) => s.user!).filter(Boolean);
    const mergedUsers = users.reduce(
      (acc, cur) => {
        acc[cur.email] = cur;
        return acc;
      },
      {} as Record<string, ChatUser>
    );
    if (user?.email) {
      mergedUsers[user.email] = {
        name: user.name!,
        email: user.email!,
        picture: user.picture!,
      };
    }
    return mergedUsers;
  }, [chats, user]);

  const uniqueUsersImages = useMemo(
    () =>
      Object.values(allUsers)
        .map((user) => user.picture!)
        .filter(Boolean),
    [allUsers]
  );

  const onChatEvent = (event: ChatEvent) => {
    if (event.name === 'openChat') {
      openChat(event.chatId);
    } else if (event.name === 'newChat') {
      openChat();
    }
  };

  const showChatList = !chat;
  const canShowChatsList = !!groupingId && app.value?.groupingSupported;

  const customMenuItems = [
    ...(showChatList
      ? []
      : canShowChatsList
      ? [
          {
            text: 'Conversation History',
            IconSvg: ClockIcon,
            onClick: () => closeChat(),
            isDisabled: !chats.length,
          },
        ]
      : []),
  ];

  if (chat?.loading) {
    return (
      <MainFlowLayout>
        <BouncingLoader />
      </MainFlowLayout>
    );
  }

  return (
    <MainFlowLayout px={showChatList ? '0px' : '21px'}>
      <Header
        px={showChatList ? '21px' : '0px'}
        user={user}
        newButtonProps={{
          onClick: () => openChat(),
          isDisabled: !!chat?.value?.progressingBotMessage || !chat?.value?.chat,
        }}
        customMenuItems={customMenuItems}
        customHeader={
          showChatList ? (
            <Flex gap="8px">
              <Flex gap="5px" align="center">
                <Icon boxSize="18px" fill="currentColor" as={ChatIcon} />
                {chats.length}
              </Flex>

              <Flex gap="5px" align="center">
                <UsersImages imagesSrc={uniqueUsersImages.slice(0, 3)} />
                {uniqueUsersImages.length}
              </Flex>
            </Flex>
          ) : undefined
        }
      />
      {showChatList ? (
        <ChatsList chats={chats} onChatEvent={onChatEvent} />
      ) : (
        <Box h="calc(100% - 50px)">
          <Messages />
        </Box>
      )}
    </MainFlowLayout>
  );
}
