import { AiChatMessage, ChatMessageAiAnswerSource, ChatMessageAiCitation } from '@worknet/models';

function getDomainFromUrl(url: string) {
  try {
    return new URL(url).hostname;
  } catch (error) {
    console.error('Invalid URL:', error);
    return '';
  }
}

export function getCitationsButtonsData(
  citationsWithParagraph?: ChatMessageAiCitation[],
  sources?: ChatMessageAiAnswerSource[]
) {
  return (
    citationsWithParagraph?.map(({ citations }) => {
      const buttonsData =
        citations?.map(({ citation, sourceIdx }) => {
          const source = sources?.[sourceIdx];
          return {
            text: (sourceIdx + 1).toString(),
            onClick: () => source?.url && window.open(source?.url, '_blank'),
            toolTip: {
              title: getDomainFromUrl(source?.url || ''),
              subTitle: source?.title || '',
              body: `"${citation}"`,
            },
          };
        }) || [];

      return buttonsData;
    }) || []
  );
}

export function formatMessageWithHtml(msg: AiChatMessage): string {
  if ('error' in msg.content) {
    return msg.content.error;
  }

  const paragraphs = msg.content.citations;
  const content = msg.content.message;

  if (!paragraphs?.length) {
    return content;
  }

  let result = content;
  const addedParagraphs = new Set<string>();
  paragraphs.forEach(({ paragraph }, index) => {
    if (addedParagraphs.has(paragraph)) {
      return;
    }
    addedParagraphs.add(paragraph);
    result = result.replaceAll(paragraph, `${paragraph}<span index=${index}></span>`);
  });
  return result;
}
