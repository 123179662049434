import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { HostPlatformContext } from '~/shared/utils/api';
import { useAuth0SocketIo } from '~/shared/utils/useAuth0SocketIo';
import HostedApp from '~/shared/components/HostedApp';

import { useZendeskBusinessContext } from '../utils';

export default function ZendeskApp() {
  const toast = useToast();
  const { user } = useAuth0();
  const { error, value } = useZendeskBusinessContext();
  const headers: Record<string, string> = value ? { 'x-wn-zendesk-domain': value.hostname } : {};
  const socket = useAuth0SocketIo(headers);
  const hostPlatformContext: HostPlatformContext | undefined = value
    ? {
        sourceContext: value.sourceContext,
        socket,
        user,
        groupingId: `${value.hostname}/${value.sourceContext.ticketId}`,
      }
    : undefined;
  useEffect(() => {
    if (error) {
      toast({
        title: 'Error',
        status: 'error',
        description: error.message,
        isClosable: true,
      });
    }
  }, [error, toast]);

  return <HostedApp hostedPlatformContext={hostPlatformContext} />;
}
